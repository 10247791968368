<template>
  <div
    v-loading.fullscreen.lock="loading"
    class="w-[100vw] flex justify-center items-center"
  >
    <div
      class="pc:w-[628px] pc:px-0 tablet:w-[628px] tablet:px-0 mobile:w-full mobile:px-[16px]"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div 
        class="
          pc:w-[628px]
          tablet:w-[628px]
          
        "
      >
        <div 
          class="
            font-bold 
            pc:text-[44px] pc:leading-[1.27] pc:tracking-[-0.4px] pc:mt-[80px]
            tablet:text-[44px] tablet:leading-[1.27] tablet:tracking-[-0.4px] tablet:mt-[80px]
            mobile:text-[30px] mobile:leading-[38px] mobile:tracking-[-0.3px] mobile:mt-[48px]
          "
        >
          {{ $route.query.jobTypeID === '20' ? 'Application' : '지원서 작성하기' }}
        </div>
        <div
          class="
            text-gray-500 
            pc:text-[22px] pc:leading-[1.27] pc:tracking-[-0.2px] pc:mt-[16px]
            tablet:text-[22px] tablet:leading-[1.27] tablet:tracking-[-0.2px] tablet:mt-[16px]
            mobile:text-[18px] mobile:leading-[24px] mobile:tracking-[-0.1px] mobile:mt-[6px]
          "
        >
          {{ jobTitle }}
        </div>
        <el-form
          ref="applicantForm"
          class="pc:mt-[8px] tablet:mt-[8px] mobile:mt-0 w-full"
          :model="applicantForm"
          :rules="rules"
          inline
        >
          <el-form-item
            class="
              custom_input_class 
              pc:mt-[32px]
              tablet:mt-[32px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="name"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'Legal Name' : '이름' }} <span class="text-orange-500">*</span>
            </div>
            <el-input
              v-model="applicantForm.name"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px] h-[48px] w-full"
              :placeholder="$route.query.jobTypeID === '20' ? 'Full name' : '이름'"
              name="name"
            />
          </el-form-item>
  
          <el-form-item
            class="
              custom_input_class 
              pc:mt-[48px]
              tablet:mt-[48px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="phone"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'Phone Number' : '연락처' }} <span class="text-orange-500">*</span>
            </div>
            <el-input
              v-model="applicantForm.phone"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px]"
              :placeholder="$route.query.jobTypeID === '20' ? '+82-00-000-0000' : '휴대폰 번호'"
            />
          </el-form-item>
          <el-form-item
            class="
              custom_input_class 
              pc:mt-[48px]
              tablet:mt-[48px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="email"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'Email' : '이메일' }} <span class="text-orange-500">*</span>
            </div>
            <el-input
              v-model="applicantForm.email"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px]"
              :placeholder="$route.query.jobTypeID === '20' ? 'Email' : '이메일 주소'"
            />
          </el-form-item>
  
          <el-form-item
            class="
              custom_input_class 
              pc:mt-[48px]
              tablet:mt-[48px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="birthday"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'Birth Date' : '생년월일' }} <span class="text-orange-500">*</span>
            </div>
            <el-input
              v-model="applicantForm.birthday"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px]"
              placeholder="1992.10.23"
              name="name"
              @keydown.native="autoCompleteBirthday"
            />
          </el-form-item>
  
          <el-form-item
            class="
              custom_input_class 
              pc:mt-[48px]
              tablet:mt-[48px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="careerPeriod"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'Years of Work Experience' : '총 경력' }} <span class="text-orange-500">*</span>
            </div>
            <div
              v-if="$route.query.jobTypeID !== '20'"
              class="
              float-left text-gray-500 text-left mt-[10px]
              pc:text-[14px] pc:leading-[22px] tracking-[-0.1px]
              tablet:text-[14px] tablet:leading-[22px]
              mobile:text-[13px] mobile:leading-[1.54]
            "
            >
              * 채용 중인 포지션과 유관한 경력으로 입력해 주세요.
            </div>
            <el-select
              v-model="applicantForm.careerPeriod"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px] w-full"
              :placeholder="$route.query.jobTypeID === '20' ? 'Years of Work Experience' : '총 경력 기간'"
              name="name"
              
              clearable
            >
              <el-option
                v-for="(_, i) in 16"
                :key="i"
                :label="`${i === 0 ? `${$route.query.jobTypeID === '20' ? 'None' : '신입 (경력없음)'}` : `${i}${$route.query.jobTypeID === '20' ? ' Years': '년'} ${i === 15 ? '이상' : ''}`}`"
                :value="i"
              />
            </el-select>
          </el-form-item>
          
          <el-form-item
            v-if="applicantForm.careerPeriod > 0"
            class="
              custom_input_class 
              pc:mt-[48px]
              tablet:mt-[48px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="previousJob"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'Previous Job' : '재직 중인 회사' }} <span class="text-orange-500">*</span>
            </div>
            <div
              v-if="$route.query.jobTypeID !== '20'"
              class="
              float-left text-gray-500 text-left mt-[10px] tracking-[-0.1px]
              pc:text-[14px] pc:leading-[22px] 
              tablet:text-[14px] tablet:leading-[22px]
              mobile:text-[13px] mobile:leading-[1.54]
            "
            >
              * 재직 중인 회사가 없다면 이전 직장을 입력해 주세요.
            </div>
            <el-input
              v-model="applicantForm.prevJob"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px]"
              :placeholder="$route.query.jobTypeID === '20' ? 'Please enter only one representative company name you worked for.' : '대표적인 회사명 1개만 입력해 주세요.'"
            />
          </el-form-item>
  
          <el-form-item
            v-show="$route.query.jobTypeID !== '20'"
            class="
              custom_input_class 
              pc:mt-[48px]
              tablet:mt-[48px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="incomingRouteId"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'How Did You Hear About Us?' : '채용 공고 확인 경로' }} <span class="text-orange-500">*</span>
            </div>
            <div
              class="
              float-left text-gray-500 text-left mt-[10px] tracking-[-0.1px]
              pc:text-[14px] pc:leading-[22px] 
              tablet:text-[14px] tablet:leading-[22px]
              mobile:text-[13px] mobile:leading-[1.54]
            "
            >
              * 채용 공고 혹은 채용 여부를 처음 확인한 경로를 알려주세요.
            </div>
            <el-select
              v-model="applicantForm.incomingRouteId"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px] w-full"
              placeholder="채용 공고 확인 경로"
              name="name"
              clearable
            >
              <el-option
                v-for="route in routeList"
                :key="route.id"
                :label="route.name"
                :value="route.id"
              />
            </el-select>
          </el-form-item>
          
          
          <el-upload
            class="inline-block custom__file__upload"
            action=""
            multiple
            :auto-upload="false"
            :on-remove="handleCareerFileRemoveChange"
            :on-change="handleCareerChange"
            :file-list="uploadList"
          >
            <el-form-item
              class="
                custom_input_class 
                pc:mt-[48px]
                tablet:mt-[40px]
                w-full
              "
              :class="$screen.breakpoint"
              prop="fileList"
            >
              <div
                class="
                text-gray-700 font-bold text-left
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
              >
                {{ $route.query.jobTypeID === '20' ? 'Resume' : '이력서' }} <span class="text-orange-500">*</span>
              </div>
              <div class="">
                <div
                  v-if="$route.query.jobTypeID === '20'"
                  class="
                  mt-[10px]
                  float-left text-gray-500 text-left tracking-[-0.1px]
                  pc:text-[14px] pc:leading-[22px] 
                  tablet:text-[14px] tablet:leading-[22px]
                  mobile:text-[13px] mobile:leading-[1.54]
                "
                >
                  File types: pdf, doc, docx, PPT
                </div>
                <div
                  v-if="$route.query.jobTypeID !== '20'"
                  class="
                  mt-[10px]
                  float-left text-gray-500 text-left tracking-[-0.1px]
                  pc:text-[14px] pc:leading-[22px] 
                  tablet:text-[14px] tablet:leading-[22px]
                  mobile:text-[13px] mobile:leading-[1.54]
                "
                >
                  * 문서는 PDF, Word, Excel, PPT 형식으로 첨부해 주세요.
                </div>
                <div
                  v-if="$route.query.jobTypeID !== '20'"
                  class="
                  float-left text-gray-500 text-left tracking-[-0.1px]
                  pc:text-[14px] pc:leading-[22px] 
                  tablet:text-[14px] tablet:leading-[22px]
                  mobile:text-[13px] mobile:leading-[1.54]
                "
                >
                  * zip 파일은 부득이한 경우(샘플 코드, 대용량 원화파일 등)를 제외하고 압축 해제 후 필요한 파일만 올려주세요.
                </div>
              </div>
              <el-input
                class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px]"
                :placeholder="$route.query.jobTypeID === '20' ? 'Attach a resume.' : '이력서 첨부 (PDF 권장)'"
                name="name"
                
                prefix-icon="icon-folder-add"
                readonly
              />
            </el-form-item>
          </el-upload>

          <el-upload
            class="inline-block custom__file__upload"
            action=""
            multiple
            :auto-upload="false"
            :on-remove="handlePortfolioFileRemoveChange"
            :on-change="handlePortfolioChange"
            :file-list="portfolioList"
          >
            <el-form-item
              class="
                custom_input_class 
                pc:mt-[48px]
                tablet:mt-[40px]
                w-full
              "
              :class="$screen.breakpoint"
              prop="portfolioFileList"
            >
              <div
                class="
                text-gray-700 font-bold text-left
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
              >
                {{ $route.query.jobTypeID === '20' ? 'Portfolio (Optional)' : '포트폴리오 (선택)' }}
              </div>
              <div class="">
                <div
                  v-if="$route.query.jobTypeID === '20'"
                  class="
                  mt-[10px]
                  float-left text-gray-500 text-left tracking-[-0.1px]
                  pc:text-[14px] pc:leading-[22px] 
                  tablet:text-[14px] tablet:leading-[22px]
                  mobile:text-[13px] mobile:leading-[1.54]
                "
                >
                  File types: pdf, doc, docx, PPT
                </div>
                <div
                  v-if="$route.query.jobTypeID !== '20'"
                  class="
                  mt-[10px]
                  float-left text-gray-500 text-left tracking-[-0.1px]
                  pc:text-[14px] pc:leading-[22px] 
                  tablet:text-[14px] tablet:leading-[22px]
                  mobile:text-[13px] mobile:leading-[1.54]
                "
                >
                  * 문서는 PDF, Word, Excel, PPT 형식으로 첨부해 주세요.
                </div>
                <div
                  v-if="$route.query.jobTypeID !== '20'"
                  class="
                  float-left text-gray-500 text-left tracking-[-0.1px]
                  pc:text-[14px] pc:leading-[22px] 
                  tablet:text-[14px] tablet:leading-[22px]
                  mobile:text-[13px] mobile:leading-[1.54]
                "
                >
                  * zip 파일은 부득이한 경우(샘플 코드, 대용량 원화파일 등)를 제외하고 압축 해제 후 필요한 파일만 올려주세요.
                </div>
              </div>
              <el-input
                class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px]"
                :placeholder="$route.query.jobTypeID === '20' ? 'Attach a portfolio.' : '포트폴리오 첨부 (PDF 권장)'"
                name="name"
                
                prefix-icon="icon-folder-add"
                readonly
              />
            </el-form-item>
          </el-upload>
  
          <el-form-item
            class="
              custom_input_class 
              pc:mt-[48px]
              tablet:mt-[48px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="url"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'Website (Optional)' : '링크 (선택)' }}
            </div>
            <div
              class="
                mt-[10px]
                float-left text-gray-500 text-left tracking-[-0.1px]
                pc:text-[14px] pc:leading-[22px] 
                tablet:text-[14px] tablet:leading-[22px]
                mobile:text-[13px] mobile:leading-[1.54]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'You can add your portfolio website here (notion, artstation or blog).' : '* 자신을 잘 드러낼 수 있는 포트폴리오 링크, 노션, Github 등을 지유롭게 입력해 주세요.' }}
            </div>
            <el-input
              v-model="applicantForm.url"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px]"
              placeholder="https://www.cookapps.com"
            />
          </el-form-item>
  
          <el-form-item
            class="
              custom_input_class 
              pc:mt-[48px]
              tablet:mt-[48px]
              w-full
            "
            :class="$screen.breakpoint"
            prop="etc"
          >
            <div
              class="
                text-gray-700 font-bold 
                pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px] pc:mt-0
                tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px] tablet:mt-0
                mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px] mobile:mt-[32px]
              "
            >
              {{ $route.query.jobTypeID === '20' ? 'Additional Information (Optional)' : '자기소개 (선택)' }}
            </div>
            <el-input
              v-model="applicantForm.etc"
              class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-[10px] w-full"
              :autosize="{ minRows: 7, maxRows: 10 }"
              type="textarea"
              :placeholder="$route.query.jobTypeID === '20' ? 'Add a cover letter or anything else you want to share.' : '추가적으로 하고 싶은 말이 있다면 자유롭게 입력해 주세요.'"
            />
          </el-form-item>
          <el-form-item
            class="
              w-full
              custom_input_class 
              pc:mt-[20px]
              tablet:mt-[20px]
              mobile:mt-[24px]
            "
            :class="$screen.breakpoint"
            prop="recruitPoolCheck"
          >
            <div
              class="flex justify-between w-full "
            >
              <el-checkbox
                v-model="applicantForm.recruitPoolCheck"
                class="
                  custom_input_class
                  pc:text-[16px] pc:leading-[1.25] pc:tracking-[-0.1px]
                  mobile:text-[14px] mobile:leading-[1.29] mobile:tracking-[-0.1px]
                  
                  
                "
              > 
                <div 
                  class="inline-block" 
                  v-html="$route.query.jobTypeID === '20' ? '' : '개인정보의 수집 및 이용에 동의'" 
                />
                <span class="text-orange-500 hover:underline">*</span>
              </el-checkbox>
              
              <div class="flex items-center select-none">
                <div
                  v-if="$route.query.jobTypeID === '20'"
                  class="
                    pc:text-[16px] pc:leading-[1.25] pc:tracking-[-0.1px] 
                    mobile:text-[14px] mobile:leading-[1.29] mobile:tracking-[-0.1px]
                    text-orange-500 cursor-pointer
                  "
                  @click.prevent="showAgreement = !showAgreement"
                >
                  {{ showAgreement ? 'Collapse' : 'Detail' }}
                </div>
                <div
                  v-else
                  class="
                    pc:text-[16px] pc:leading-[1.25] pc:tracking-[-0.1px] 
                    mobile:text-[14px] mobile:leading-[1.29] mobile:tracking-[-0.1px]
                    text-orange-500 cursor-pointer
                  "
                  @click.prevent="showAgreement = !showAgreement"
                >
                  {{ showAgreement ? '접기' : '자세히' }}
                </div>
              </div>
            </div>
            <div 
              v-if="$route.query.jobTypeID === '20'"
              class="
                pc:text-[16px] pc:leading-[1.25] pc:tracking-[-0.1px]
                mobile:text-[14px] mobile:leading-[1.29] mobile:tracking-[-0.1px]
              "
            >
              I understand that the information I provide will be used in accordance with CookApps’s applicant and candidate privacy policy.
            </div>
          </el-form-item>
          <el-input
            v-if="showAgreement === true"
            v-model="agreement"
            class="pc:mt-[16px] w-full"
            readonly
            :autosize="{ minRows: 5, maxRows: 5 }"
            type="textarea"
          />
          <div class="flex justify-center">
            <CustomButton
              class="
                pc:w-[194px]
                pc:mt-[64px] pc:mb-[80px]
                mobile:mt-[41px] mobile:mb-[64px]
                mobile:w-full
              "
              :text="$route.query.jobTypeID === '20' ? 'APPLY' : '제출하기'"
              :size="$screen.breakpoint === 'mobile'? 'lg':'2xl'"
              rounded
              color="secondary"
  
              @click.native="submit"
            />
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { getOpenJobs, getRoute, getUploadUrl } from '@/api/job';
import CustomButton from '../../components/Button/customButton.vue';
import passwordModalVue from '../../components/modal/passwordModal.vue';
import { registerSimple } from '@/api/applicant';
import axios from 'axios';

export default {
  components: {
    CustomButton
  },
  data() {
    return {
      loading: false,
      jobList: [],
      applicantForm :{incomingRouteId: this.$route.query.jobTypeID === '20' ? 12 : null},
      requiredRule: { required: true, message: '필수 입력 항목입니다.', trigger: 'blur'},
      routeList: [],
      fileList: null,
      uploadList: [],
      portfolioFileList: null,
      portfolioList: [],
      dialogVisible:true,
      rules: {
        name: [{ required: true, validator: this.requiredRule, trigger: 'blur', message: '필수 입력 항목입니다.' }],
        phone: [{ required: true, validator: this.requiredRule, trigger: 'blur', message: '필수 입력 항목입니다.' }],
        email: [{ required: true, validator: this.requiredRule, trigger: 'blur', message: '필수 입력 항목입니다.' }],
        birthday: [{ required: true, validator: this.requiredRule, trigger: 'blur', message: '필수 입력 항목입니다.' }],
        careerPeriod: [{ required: true, validator: this.requiredRule, trigger: 'change', message: '필수 입력 항목입니다.' }],
        incomingRouteId: [{ required: true, validator: this.requiredRule, trigger: 'change', message: '필수 입력 항목입니다.' }],
        fileList: [{ required: true, validator: this.dataValidator, trigger: 'change', message: '필수 입력 항목입니다.'}],
        recruitPoolCheck: [{ required: true, validator: (r, v, c)=>{ !v ? c(new Error()) : c(); }, trigger: 'change', message: '필수 입력 항목입니다.'}],
      },
      showAgreement: false,
      agreement: this.$route.query.jobTypeID === '20'?`Applicant and Candidate Privacy Policy
 
 This Candidate Privacy Notice sets out how your personal information as a job applicant will be handled and protected by Cookapps. We appreciate your interest in Cookapps, and we take care to process and use your personal information responsibly.
  
 Types of information we collect
 This policy covers the information you share with us and/or which may be acquired or produced by Cookapps during the application or recruitment process including:
 Personal data is information relating to you, such as resume, cover letter, previous and/or relevant work experience or other experience or other information you provide to us in support of an application and/or the application and recruitment process.
 If you apply to one of our open positions published in our “Career” webpage, we will collect your legal name, full name, e-mail address, resume or portfolio. 
  
 How we process your personal data
 We process personal data for the purposes of:
 : Assessing your skills, qualifications, and interests against our career opportunities
 : Communicating with you during the recruitment process
 : Making hiring decisions
 : Checking for compliance with the necessary requirements for work in a particular country
 : Improving our recruiting practices
 : Contacting you about vacancies at Cookapps which we think you may be interested in
 If you are offered and accept employment with Cookapps, the information collected during the application and recruitment process will become part of our employment record.
  
 How long we keep your personal data
 If your application is successful and you are hired by Cookapps, your personal data will be retained as part of our employee profile. If your application is unsuccessful, then we will delete your personal data unless we think that you may be suitable for other roles at Cookapps in the future. In this case we will store personal data in our talent pool for no longer than three years from the date we informed you about the decision on your application. If you do not want us to retain your personal data for consideration for future opportunities, please let us know by contacting us at recruit@cookapps.com
  
 Confidentiality
 During the recruitment process, it may be necessary for Cookapps to disclose confidential information to evaluate your suitability for the position you have applied for. All information that is shared with you by Cookapps in the recruitment process and which is not already in the public domain is confidential and you agree to treat it as confidential. We will not use or disclose such confidential information to any person, company.
  
 Your rights
 You have the right to obtain a copy of your personal data, to ask for that information to be corrected, to object to the processing of your personal data, and to request its deletion. We will not use or disclose such confidential information to any person, company. If you want to exercise any of these rights, please send us a request at recruit@cookapps.com.
  
 Changes to this Privacy Notice
 Cookapps reserves the right to modify this Candidate Privacy Notice at any time. Please review this written document periodically to ensure you are aware of any changes.
 `:`(주)쿡앱스는 지원자의 입사전형 진행 및 인재풀 구성을 위해 아래와 같은 목적으로 개인정보를 수집 및 이용합니다.

이력서 및 경력기술서 제출 시 전형결과 안내 및 자격요건 확인에 필요한 최소한의 개인 정보만 기입해주시길 바랍니다.
주민번호, 가족관계, 종교, 신체정보 등의 불필요한 개인 정보는 삭제해주시길 바랍니다.

1.개인정보의 수집 및 이용 목적

입사전형 진행,자격요건 확인, 전형별 합격여부 확인,전형결과 안내


2. 수집하는 개인정보의 항목

필수정보 : 성명, 생년월일,이메일주소, 휴대전화번호,국적,경력사항(입사일,퇴사일,회사명,직급,담당업무,프로젝트 수행이력) 학력,전공,병역사항,생성정보(접속정보,로그기록 등)

선택정보 : 자격사항, 수상내역, 어학사항, 봉사활동, 보훈사항, 논문사항 등


3. 개인정보의 보유 및 이용기간

1) 채용여부 확정 이후 최대 3년까지 보관되며 이후 지체없이 파기합니다.

2) 보관기간 이내라도 입사지원자가 개인정보의 삭제를 원하는 경우 지체없이 해당 정보를 파기합니다.

3) 개인정보는 인재풀 등록에 사용될 수 있습니다. 인재풀 등록을 원하지 않을 경우 언제든지 거부 요청을 할 수 있습니다.
      `,
    };
  },
  computed: {
    jobTitle() {
      const job = this.jobList.filter(job => job.id === parseInt(this.$route.query.jobId))[0];
      return job ? job.alias : '' ;
    }
  },
  async created() {
    this.getRoute();
    this.getOpenJobs();
  },
  methods: {
    async getRoute() {
      const result = await getRoute({ checkIncoming: true });
      this.routeList = result.data.items;
    },
    async getOpenJobs() {
      const result = await getOpenJobs();
      this.jobList = result.data.items;
    },
    fileSelect(e) {
      console.log(e.target.files[0]);
      this.fileList = e.target.files;
    },
    handleCareerChange(file, fileList) {
      this.uploadList = fileList;
      this.$refs.applicantForm.validateField('fileList');
    },
    handleCareerFileRemoveChange(file, fileList) {
      this.uploadList = fileList;
      this.$refs.applicantForm.validateField('fileList');
    },
    handlePortfolioChange(file, fileList) {
      this.portfolioList = fileList;
      this.$refs.applicantForm.validateField('portfolioFileList');
    },
    handlePortfolioFileRemoveChange(file, fileList) {
      this.portfolioList = fileList;
      this.$refs.applicantForm.validateField('portfolioFileList');
    },
    dataValidator(rule, value, callback) {
      if (!this.uploadList.length) {
        callback(new Error());
      } else {
        callback();
      }
    },
    openPasswordModal() {
      this.$modal.open(passwordModalVue, this.applicantForm);
    },

    async uploadFiles(data) {
      let fileList = [];

      if (data.length) {
        const sParam = data.map(file => {
          return {
            name: file.name,
            type: file.raw.type
          };
        });

        const signedResult = await getUploadUrl({ items: sParam });
        const uploadUrlList = signedResult.data.uploadUrlList;
        const downloadUrlList = signedResult.data.downloadUrlList;

        const promiseList = uploadUrlList.map((url, idx) => {
          return new Promise((resolve) => {
            axios.put(url, data[idx].raw, {
              headers: {
                'Content-Type': data[idx].raw.type
              }
            }).then(() => {
              resolve(true);
            }).catch(() => {
              resolve(false);
            });
          });
        });

        const result = await Promise.all(promiseList);

        if (result.indexOf(false) > -1) {
          this.$alert(this.$t('recruit.apply_upload_fail'), this.$t('recruit.apply_fail_title'), { dangerouslyUseHTMLString: true });
          this.dialogLoading = false;

          return false;
        }

        downloadUrlList.forEach((url, idx) => {
          sParam[idx].url = url;
        });

        fileList = downloadUrlList.map((url, idx) => {
          return {
            ...sParam[idx],
            url
          };
        });
      }

      return fileList;
    },
    async submit() {
      this.$refs.applicantForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const resumeList = await this.uploadFiles(this.uploadList);
          const portfolioFileList = await this.uploadFiles(this.portfolioList);
          const fileList = resumeList.concat(portfolioFileList);
          const urlListObj = [{ url: this.applicantForm.url }];

          this.applicantForm['jobId'] = this.$route.query.jobId;

          const applyResult = await registerSimple({
            applicantForm: this.applicantForm,
            urlList: urlListObj,
            fileList,
          });

          if (applyResult.data.success) {
            this.loading = false;
            this.$router.push({
              name: 'Recruit Apply Complete', 
              params: {
                jobTitle: this.jobTitle ,
                name: this.applicantForm.name
              }
            });
          } else {
            alert('실패');
            this.loading = false;
          }
        } else {
          alert('입력 정보를 확인해 주세요.');
          console.log('error submit!!');
          return false;
        }
      });
    },
    autoCompleteBirthday: function (key) {
      const birthday = this.applicantForm.birthday;
      const year = (birthday || '').substr(0,4);
      const firstDot = (birthday || '').substr(4,1);
      const month = (birthday || '').substr(5,2);
      const secondDot = (birthday || '').substr(7,1);
      if( key.keyCode == 8 || key.keyCode == 46 || key.key === '.' ) return false;
      if((birthday || '').length > 3 && firstDot !== '.'){
        this.applicantForm.birthday = year + '.';
      }else if((birthday || '').length > 6 && secondDot !== '.'){
        this.applicantForm.birthday = year + '.' + month + '.';
      }
    },
    
  },

};
</script>
<style lang="scss" >
.custom_input_class.el-form-item {
  margin-bottom: 0px;
}

:is(.pc) .custom_input_class {
  .el-checkbox__label {
      font-size: 16px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.25 !important;
      letter-spacing: -0.1px !important;
  }
  .el-form-item__content { 
    width: 100%;
  }
  .el-textarea__inner {
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 14px 16px;
  }
  .el-input__inner {
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
  }
  .el-input__prefix {
    padding-left: 9px;
  }
  .el-input--prefix .el-input__inner {
      padding-left: 48px;
  }
  .el-input__icon.icon-folder-add {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      
      font-size: 24px;
      color: #999999;
    }
  }
}
:is(.tablet) .custom_input_class {
  .el-checkbox__label {
      font-size: 16px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.25 !important;
      letter-spacing: -0.1px !important;
  }
  .el-form-item__content { 
    width: 100%;
  }
  .el-textarea__inner {
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 14px 16px;
  }
  .el-input__inner {
    width: 300px;
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
  }
  .el-input__prefix {
    padding-left: 9px;
  }
  .el-input--prefix .el-input__inner {
      padding-left: 48px;
  }
  .el-input__icon.icon-folder-add {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      
      font-size: 24px;
      color: #999999;
    }
  }
}
:is(.mobile) .custom_input_class {
  .el-checkbox__label {
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.29 !important;
    letter-spacing: -0.1px !important;
  }
  .el-form-item__content { 
    width: 100%;
  }
  .el-textarea__inner {
    height: 42px;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.1px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 12px;
  }
  // .el-input__inner {
  //   height: 42px;
  //   font-size: 14px;
  //   border-color: #e1e1e1;
  //   color: #333333;
  //   padding: 0 12px;
  // }
  .el-input__inner {
    height: 48px;
    font-size: 16px;
    border-color: #e1e1e1;
    color: #333333;
    padding: 0 14px;

    width: 114.28571428571428571428571428571%;

    transform: scale(0.875);
    transform-origin: left top;
  }
  .el-input__prefix {
    padding-left: 9px;
  }
  .el-input--prefix .el-input__inner {
      padding-left: 48px;
  }
  .el-input__icon.icon-folder-add {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      
      font-size: 24px;
      color: #999999;
    }
  }
}

.el-checkbox {
  display: flex;
  align-items: center;
  height: 20px;
}
.el-checkbox__input {
  top: 2px;
}
.el-checkbox__inner {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
}


.el-checkbox__inner::after {
  left: 6px;
  width: 6px;
  height: 12px;
}

.el-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

