<template>
  <div
    class="survey__modal__back"
    :class="$screen.breakpoint === 'pc' ? '': 'h-full'"
    :style="$screen.breakpoint === 'pc' ? {left: 'calc(50% - 265px)', width: '520px'} : {left: 0, width: '100%', height:'100vh'}"
  >
    <div
      class="modal p-8 "
      :class="$screen.breakpoint === 'pc' ?'mt-[calc(50vh-187px)]': 'h-full'"
      :style="{'border-radius': $screen.breakpoint === 'pc' ? '8px' : '0px'}"
    >
      <div class="text-left">
        <span class="text-3xl font-bold inline-block relative">
          <span class="z-10 relative">비밀번호 설정</span>
        </span>
        <button
          class="float-right el-icon el-icon-close text-3xl font-bold"
          @click="close"
        />
      </div>
      <div class="mt-[24px]">
        <el-form
          ref="passForm"
          :model="passForm"
          class="custom-form"
          :rules="rules"
        >
          <div class="custom_input_class">
            <el-form-item
              prop="password"
            >
              <el-input
                v-model.trim="passForm.password"
                show-password
                :minlength="8"
                :maxlength="20"
                placeholder="비밀번호"
                type="password"
              />
            </el-form-item>
            <el-form-item
              prop="passwordConfirm"
            >
              <el-input
                v-model.trim="passForm.passwordConfirm"
                show-password
                :minlength="8"
                :maxlength="20"
                placeholder="비밀번호 확인"
                type="password"
                class="!pb-0"
                @keyup.native.enter="changePassword"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="flex flex-col">
        <div
          class="
            float-left text-gray-600
            pc:text-[14px] leading-[22px] tracking-[-0.1px]
          "
        >
          * 비밀번호는 영문, 숫자를 포함하여 공백없이 8 ~ 20자로 설정해 주세요.
        </div>
        <div
          class="
            float-left text-gray-600
            pc:text-[14px] leading-[22px] tracking-[-0.1px]
          "
        >
          * 설정된 비밀번호는 지원서 확인에 사용됩니다.
        </div>
      </div>
      <div>
        <div class="flex justify-end pc:mt-[40px]">
          <el-button
            class="mt-10"
            type="primary"
            style="background-color: #ff9933; height: 48px; font-size: 18px"
            :style="{width: $screen.breakpoint === 'pc' ? '140px' : '100%'}"
            @click="submit"
          >
            설정완료
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>import { putSurveyResult } from '../../api/applicant';


export default {
  props:['params'],
  data(){
    return{
      passForm: {
        password: '',
        passwordConfirm: ''
      },
      rules: {
        password: { validator: this.validatePassword1, trigger: 'blur' },
        passwordConfirm: { validator: this.validatePassword2, trigger: 'blur' }
      }
    };
  },
  mounted() {
    document.getElementById('app').style.overflowY = 'hidden';
    document.getElementById('app').style.height = '100vh';
  },
  destroyed() {
    document.getElementById('app').style.overflowY = 'auto';
    document.getElementById('app').style.height = '';
  },
  methods: {
    close(){
      this.$emit('close');
    },
    validatePassword1(rule, value, callback) {
      const pw = this.passForm.password;
      const num = pw.search(/[0-9]/g);
      const eng = pw.search(/[a-z]/ig);

      if(pw.length < 8 || pw.length > 20){
        callback(new Error('비밀번호는 8자리 ~ 20자리 이내로 입력해주세요.'));
      }else if(pw.search(/\s/) != -1){
        callback(new Error('비밀번호는 공백 없이 입력해주세요.'));
      }else if(num < 0 || eng < 0 ){
        callback(new Error('비밀번호를 영문,숫자를 혼합하여 입력해주세요.'));
      }else {
        callback();
      }
    },
    validatePassword2(rule, value, callback) {
      const pw = this.passForm.password;
      const pwConfirm = this.passForm.passwordConfirm;

      if(pw !== pwConfirm){
        callback(new Error('비밀번호가 다릅니다'));
      }else {
        callback();
      }
    },
    async submit(){
      this.$refs['passForm'].validate((valid) => {
        if (valid) {
          console.log(this.params);
          if(confirm('서류 제출 후엔 내용 수정이 불가합니다. 제출하시겠습니까?')){
            console.log('a');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
};
</script>
<style >
.survey__modal__back{
  position: absolute;
  top: 0px;
  width: 560px;
  z-index: 99999;
}
.modal {
  background-color: white;
}
/* .custom_input_class>.el-form-item {
  margin-bottom: 0px;
} */
</style>